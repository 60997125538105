.button {
  background-color: #bb3036;
  border: #bb3036;
  border-style: solid;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button:hover {
  background-color: white;
  border: black;
  border-style: solid;
  color: black;
}

.floating-image {
  overflow: hidden;
  transform: translatey(0px);
  border-radius: 16px;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
